@if (caseItem(); as caseItem) {
  <div class="case-info__wrapper">
    <app-ui-case-card
      class="case-info__case"
      [item]="caseItem"
      [isHovered]="false"
      [showDescription]="false"
      [showRevision]="false"
      [showTags]="false"
    >
    </app-ui-case-card>
    @if (caseItem.enabled) {
      <div class="case-info__buttons">
        <div class="tw-flex tw-gap-2 tw-flex-wrap tw-grow">
          <div class="tw-flex tw-gap-2 tw-grow">
            <button
              class="btn btn-primary--design btn-md !tw-rounded-lg tw-grow"
              [disabled]="!casesAdded().canAdd"
              (click)="addCase(caseItem.id)"
            >
              {{ 'LOCAL.CASES.MODAL.REVISION.ADD_TO_BATTLE' | translate }}
              <!-- @if (casesAdded().amount; as amount) {
                ({{ amount }})
              } -->
            </button>
          </div>
          <div class="tw-flex tw-gap-2 tw-grow">
            @if (!caseItem.onlyBattles) {
              <button
                class="btn btn-default--design btn-md !tw-rounded-lg tw-grow"
                (click)="navigateToCase(caseItem.id)"
              >
                {{ 'LOCAL.CASES.MODAL.REVISION.OPEN' | translate }}
              </button>
            }

            <button
              class="btn btn-basic--design btn-md sq !tw-rounded-lg"
              [class.table]="isTable()"
              (click)="toggleListView()"
            >
              @if (isTable()) {
                <mat-icon
                  class="icon sm"
                  [svgIcon]="'sort-table'"
                ></mat-icon>
              } @else {
                <mat-icon
                  class="icon sm"
                  [svgIcon]="'sort-block'"
                ></mat-icon>
              }
            </button>
          </div>
        </div>
      </div>
    }
    <div class="case-info__descriptions">
      <div class="tw-flex tw-gap-2 tw-flex-col">
        <div class="tw-flex tw-gap-2 tw-flex-wrap">
          <div class="tw-flex tw-flex-col tw-grow tw-gap-2 tw-text-[1.125rem]">
            <div class="tw-text-[var(--color-gray-100)]">{{ caseItem.name }}</div>
            @if (caseItem.type !== CaseTypeEnum.FREE) {
              <app-currency [value]="caseRevision()?.revision?.price || caseItem.lastRevision.price"></app-currency>
            }
            @if (showRevisionSelector()) {
              @if (caseRevision(); as caseRevision) {
                <div class="tw-flex tw-flex-wrap tw-gap-1 tw-text-xs tw-text-[var(--color-gray-200)]">
                  <div class="date-label">{{ 'LOCAL.CASES.MODAL.REVISION.PERIOD' | translate }}:</div>
                  <div class="tw-flex tw-gap-1 tw-flex-wrap">
                    <div>
                      <span class="period-date">{{ caseRevision.revision.createdAt | date: 'dd.MM.yyyy' }} </span>
                      <span class="period-time"> {{ caseRevision.revision.createdAt | date: '(hh:mm:ss)' }}</span>
                    </div>
                    @if (caseRevision.revision.updatedAt) {
                      <span> - </span>
                      <div>
                        <span class="period-date">{{ caseRevision.revision.updatedAt | date: 'dd.MM.yyyy' }} </span>
                        <span class="period-time"> {{ caseRevision.revision.updatedAt | date: '(hh:mm:ss)' }}</span>
                      </div>
                    }
                  </div>
                </div>
              }
            }
          </div>
        </div>
      </div>
    </div>
    @if (showRevisionSelector() && revisionsList() && caseItem.type !== CaseTypeEnum.FREE) {
      <div class="case-info__revision">
        <div class="range_change-label">{{ 'LOCAL.CASES.MODAL.REVISION.REVISION' | translate }}:</div>
        <app-ui-autocomplete-menu
          [selectionItems]="{ items: revisionsList(), activeIndex: caseRevisionIndex() }"
          (activeItemIndex)="getCaseRevision($event)"
        ></app-ui-autocomplete-menu>
      </div>
    }
  </div>
}
