import { DatePipe, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, effect, input, output, signal } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { ICaseItemDtoV2, ICaseRevisionInfo, ICaseType } from '@dev-fast/types';
import { TranslateModule } from '@ngx-translate/core';

import { CurrencyComponent } from '@app/core/currency';
import { AutocompleteMenuModule, CaseCardComponentModule } from '@app/ui/components/index';
import { ISelectionMenuItem } from '@app/ui/components/lib/form-elements/autocomplete-menu/shared';

@Component({
  selector: 'app-ui-case-header-info',
  templateUrl: './case-info-header.component.html',
  styleUrls: ['./case-info-header.component.scss'],
  standalone: true,
  imports: [
    NgTemplateOutlet,
    MatIconModule,
    DatePipe,
    CurrencyComponent,
    TranslateModule,
    CaseCardComponentModule,
    // NgScrollbarModule,
    // LangRouterModule,
    AutocompleteMenuModule,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CaseInfoHeaderComponent {
  readonly revisionItems = input<ICaseItemDtoV2[]>([]);
  readonly caseItem = input<ICaseItemDtoV2 | null>(null);
  readonly caseRevision = input<ICaseRevisionInfo | null>(null);
  readonly casesAdded = input.required<{ amount: number; canAdd: boolean }>();
  readonly caseRevisionIndex = input<number>(0);
  readonly showRevisionSelector = input<boolean>(true);
  readonly isTable = input<boolean>(true);
  readonly revisionsList = signal<ISelectionMenuItem[]>([]);

  readonly onaddCase = output<number>();
  readonly onremoveCase = output<number>();
  readonly onnavigateToCase = output<number>();
  readonly onselectRevision = output<number>();
  readonly ontoggleView = output<void>();

  readonly CaseTypeEnum: typeof ICaseType = ICaseType;

  constructor() {
    effect(
      () => {
        const currentRevision = this.caseRevision();
        if (currentRevision && this.showRevisionSelector()) {
          this.#revisionsListConstructor(currentRevision);
        }
      },
      { allowSignalWrites: true },
    );
  }

  #revisionsListConstructor(caseRevision: ICaseRevisionInfo): void {
    this.revisionsList.set(
      caseRevision.revisionIds.reduce((acc: ISelectionMenuItem[], cur) => {
        return [...acc, { name: cur.toString(), title: cur.toString() }];
      }, []),
    );
  }
  addCase(caseId: number): void {
    this.onaddCase.emit(caseId);
    // if (LanguageService.getBaseUrl(this.router.url) !== '/game/case-battle') {
    //   this.router.navigate([this.languageService.getCurrentLangUrl('/game/case-battle')]);
    // }
  }
  removeCase(caseId: number): void {
    this.onremoveCase.emit(caseId);
  }
  getCaseRevision(revisionIndex: number): void {
    this.onselectRevision.emit(revisionIndex);
  }
  navigateToCase(caseId: number): void {
    this.onnavigateToCase.emit(caseId);
  }
  toggleListView(): void {
    this.ontoggleView.emit();
  }
}
