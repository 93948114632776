import { inject, Injectable } from '@angular/core';
import { CasesBackendService } from '@dev-fast/backend-services';
import { ICaseRevisionInfo, ModalNames, PROJECT } from '@dev-fast/types';
import { Navigate } from '@ngxs/router-plugin';
import { Select } from '@ngxs/store';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { map, Observable } from 'rxjs';

import { EnvironmentService } from '@app/core/environment-service';
import { AddCaseItem, BattleSelectedCasesState, RemoveCaseItems } from '@app/core/state/games-store';
import { CloseModal, OpenModal } from '@app/core/state/modals';

@Injectable()
export class CaseInfoService {
  readonly #casesApiService = inject(CasesBackendService);
  readonly #environmentService = inject(EnvironmentService);

  readonly #project = this.#environmentService.getEnvironment().PROJECT;

  @Select(BattleSelectedCasesState.addedIds) readonly addedIds$!: Observable<Record<number, number>>;

  @Dispatch() addCaseItem = (caseId: number): AddCaseItem => new AddCaseItem(caseId);
  @Dispatch() removeCaseItem = (caseId: number): RemoveCaseItems => new RemoveCaseItems(caseId);
  @Dispatch() openModal = (modalName: ModalNames, payload?: any): OpenModal => new OpenModal(modalName, payload);
  @Dispatch() closeModal = (modalName: ModalNames): CloseModal => new CloseModal(modalName);
  @Dispatch() navigate = (path: any[]): Navigate => new Navigate(path);

  readonly casesAdded$ = (caseId: number | undefined): Observable<{ amount: number; canAdd: boolean }> =>
    this.addedIds$.pipe(
      map((addedIds) => {
        const ids = Object.values(addedIds);
        if (!caseId) {
          return { amount: ids.length, canAdd: false };
        }
        return { amount: ids.length, canAdd: ids.length < 10 };
      }),
    );

  getDetailedCase = (caseId: number, revisionIndex: number): Observable<ICaseRevisionInfo> => {
    return this.#casesApiService.getCaseRevisionById(caseId, revisionIndex);
  };

  addCase(caseId: number): void {
    this.addCaseItem(caseId);
    this.closeModal(ModalNames.CASE_REVISION);
    if (this.#project === PROJECT.MARKET) {
      this.navigate(['/case-battle']);
    } else {
      this.navigate(['/game/case-battle']);
    }
  }
  navigateToCaseItem(caseId: number): void {
    if (this.#project === PROJECT.MARKET) {
      this.closeModal(ModalNames.CASE_REVISION);
      this.openModal(ModalNames.CASE, caseId);
    } else {
      this.navigate(['/game/cases/case', caseId]);
      this.closeModal(ModalNames.CASE_REVISION);
    }
  }
}
