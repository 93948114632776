import { AsyncPipe, NgClass, TitleCasePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, input, OnInit, output, signal } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { MatIconModule } from '@angular/material/icon';
import { ICaseItemDtoV2, ICaseRevisionInfo, ICaseRevisionItem } from '@dev-fast/types';
import { TranslateModule } from '@ngx-translate/core';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { filter, switchMap, tap } from 'rxjs';

import { PossibleItemsListComponentModule, SkinCardSAComponent } from '@app/ui/components/index';

import { CaseInfoService } from './case-info.service';
import { CaseInfoHeaderComponent } from './case-info-header/case-info-header.component';

@Component({
  selector: 'app-case-info',
  templateUrl: './case-info.component.html',
  styleUrls: ['./case-info.component.scss'],
  standalone: true,
  imports: [
    AsyncPipe,
    NgClass,
    TitleCasePipe,
    TranslateModule,
    NgScrollbarModule,
    MatIconModule,
    PossibleItemsListComponentModule,
    SkinCardSAComponent,
    CaseInfoHeaderComponent,
  ],
  providers: [CaseInfoService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CaseInfoSAComponent implements OnInit {
  readonly #caseInfoService = inject(CaseInfoService);

  readonly caseItemId = input.required<number>();
  readonly showRevisionSelector = input<boolean>(true);

  readonly caseLoaded = output<void>();

  readonly caseRevisionsList = signal<number[] | null>(null);
  readonly caseItem = signal<ICaseItemDtoV2 | null>(null);
  readonly caseRevision = signal<ICaseRevisionInfo | null>(null);
  readonly caseRevisionIndex = signal<number>(0);
  readonly revisionItems = signal<ICaseRevisionItem[]>([]);
  readonly isTable = signal<boolean>(true);

  readonly casesAdded = signal<{ amount: number; canAdd: boolean }>({ amount: 0, canAdd: false });

  readonly caseRevisionObserver$ = toObservable(this.caseRevisionIndex);

  ngOnInit(): void {
    this.caseRevisionObserver$
      .pipe(
        filter(() => !!this.caseItemId()),
        switchMap((revisionIndex: number) => {
          return this.#caseInfoService.getDetailedCase(this.caseItemId(), revisionIndex).pipe(
            tap((revisionInfo) => {
              if (revisionInfo) {
                this.caseLoaded.emit();
                this.caseItem.set({ ...revisionInfo.revision.case, lastRevision: revisionInfo.revision });
                this.caseRevision.set(revisionInfo);
                this.revisionItems.set(revisionInfo.revision.items);
                if (this.showRevisionSelector()) {
                  this.caseRevisionsList.set(revisionInfo.revisionIds);
                }
              }
            }),
          );
        }),
      )
      .subscribe();
    this.caseRevisionObserver$
      .pipe(
        filter(() => !!this.caseItemId()),
        switchMap(() => {
          return this.#caseInfoService.casesAdded$(this.caseItemId()).pipe(
            tap((val) => {
              this.casesAdded.set(val);
            }),
          );
        }),
      )
      .subscribe();
  }

  calcChancePercent(count: number): string {
    return `${count / 1000} %`;
  }
  findCurrentCase(caseItems: ICaseItemDtoV2[], caseId: number): number {
    return caseItems.filter((caseItem) => caseItem.id === caseId).length;
  }
  addCase(caseId: number): void {
    this.#caseInfoService.addCase(caseId);
  }
  removeCase(caseId: number): void {
    this.#caseInfoService.removeCaseItem(caseId);
  }
  toggleListView(): void {
    this.isTable.update((val) => !val);
  }
  sortItems(caseItems: ICaseRevisionItem[]): ICaseRevisionItem[] {
    return caseItems.sort((a, b) => b.inventoryItem.price - a.inventoryItem.price);
  }
  selectRevisionIndex(index: number): void {
    this.caseRevisionIndex.set(index);
  }
  navigateToCase(caseId: number): void {
    this.#caseInfoService.navigateToCaseItem(caseId);
  }
}
