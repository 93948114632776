@if (caseItem(); as caseItem) {
  <div class="revision-modal__wrapper">
    <app-ui-case-header-info
      [caseItem]="caseItem"
      [caseRevision]="caseRevision()"
      [casesAdded]="casesAdded()"
      [caseRevisionIndex]="caseRevisionIndex()"
      [showRevisionSelector]="showRevisionSelector()"
      [isTable]="isTable()"
      (onaddCase)="addCase($event)"
      (onremoveCase)="removeCase($event)"
      (onnavigateToCase)="navigateToCase($event)"
      (ontoggleView)="toggleListView()"
      (onselectRevision)="selectRevisionIndex($event)"
    >
    </app-ui-case-header-info>
    @if (revisionItems().length) {
      @if (!isTable()) {
        <div class="revision-modal__titles-wrapper">
          <div class="revision-modal__title revision-modal__title_skin">{{ 'LOCAL.CASES.MODAL.REVISION.SKIN' | translate }}</div>
          <div class="revision-modal__title revision-modal__title_price">{{ 'LOCAL.CASES.MODAL.REVISION.PRICE' | translate }}</div>
          <div class="revision-modal__title revision-modal__title_odds">{{ 'LOCAL.CASES.MODAL.REVISION.RANGE' | translate }}</div>
          <div class="revision-modal__title revision-modal__title_odds">{{ 'LOCAL.CASES.MODAL.REVISION.CHANCE' | translate }}</div>
        </div>
      }
      <ng-scrollbar
        [autoHeightDisabled]="false"
        [pointerEventsMethod]="'viewport'"
        [ngClass]="['custom-size__scrollbar-wrapper', 'mask-Y']"
      >
        <div class="revision-modal__items-wrapper">
          @if (isTable()) {
            <div class="revision-modal__items-container revision-modal__items-container_table">
              <app-ui-possible-items-list
                [isCompact]="true"
                [caseId]="caseItem.id"
                [caseItems]="revisionItems()"
                [isHovered]="false"
              >
              </app-ui-possible-items-list>
            </div>
          } @else {
            <div class="revision-modal__items-container">
              @for (item of sortItems(revisionItems()); track item; let index = $index) {
                <app-ui-skin-card
                  [index]="index"
                  [weaponName]="
                    item.inventoryItem.baseItem.shortName
                      ? item.inventoryItem.baseItem.shortName
                      : (item.inventoryItem.baseItem.type | titlecase)
                  "
                  [skinName]="item.inventoryItem.baseItem.skin"
                  [skinWear]="item.inventoryItem.baseItem.exterior"
                  [price]="item.inventoryItem.price"
                  [chance]="item.count"
                  [statTrak]="item.inventoryItem.baseItem.statTrak"
                  [avatarSrc]="item.inventoryItem.baseItem.icon"
                  [range]="{ min: item.rangeFrom, max: item.rangeTo }"
                >
                </app-ui-skin-card>
              }
            </div>
          }
        </div>
      </ng-scrollbar>
    }
  </div>
}
